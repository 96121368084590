import CreatedEvent from '@/components/projects/events/CreatedEvent.vue'
import OfferEvent from '@/components/projects/events/OfferEvent.vue'
import OfferReceived from '@/components/projects/events/OfferReceived.vue'
import ResolutionSubmitted from '@/components/projects/events/ResolutionSubmitted.vue'
import ResolutionAccepted from '@/components/projects/events/ResolutionAccepted.vue'
import ResolutionDeclined from '@/components/projects/events/ResolutionDeclined.vue'
import ResolutionChangesSubmitted from '@/components/projects/events/ResolutionChangesSubmitted.vue'
import SignCTA from '@/components/projects/events/SignCTA.vue'
import PhaseReview from '@/components/projects/events/PhaseReview.vue'
import PhaseSubmit from '@/components/projects/events/PhaseSubmit.vue'
import PhaseCanceled from '@/components/projects/events/PhaseCanceled.vue'
import ProjectPhaseStart from '@/components/projects/events/ProjectPhaseStart.vue'
import Default from '@/components/projects/events/Default.vue'
import ResolutionDefault from '@/components/projects/events/ResolutionDefault.vue'
import ResolutionModify from '@/components/projects/events/resolutions/ResolutionModify.vue'
import ParticipatedEvent from '@/components/projects/events/ParticipatedEvent.vue'
import OfferExpired from '@/components/projects/events/OfferExpired.vue'
import OfferEditing from '@/components/projects/events/OfferEditing.vue'

export enum Events {
  created = 'project:created',
  offers = 'project:offers',
  participate = 'designer:participated',
  offerSubmitted = 'project:offer-submitted',
  offerReceived = 'project:offer-received',
  offerSelectedDesigner = 'project:offer-selected-designer',
  offerSelected = 'project:offer-selected',
  phaseStarted = 'project:phase-started',
  phaseStartedDesigner = 'project:phase-started-designer',
  resolutionSubmitted = 'project:resolution-submitted',
  resolutionAccepted = 'project:resolution-accepted',
  resolutionDeclined = 'project:resolution-declined',
  resolutionChangesSubmitted = 'project:resolution-changes-submitted',
  signCTA = 'project:sign-cta',
  projectCancelled = 'project:cancelled',
  projectCancelledDesigner = 'project:cancelled_designer',
  phaseSubmit = 'project:phase-designs-submit',
  phaseReview = 'project:phase-designs-review',
  phaseCanceled = 'project:phase-canceled',
  projectPhaseEnded = 'project:phase-ended',
  projectPhaseStart = 'project:phase-start',
  resolutionSubmittedDesignerPostpone = 'project:resolution-submitted-designer-postpone',
  resolutionSubmittedPostpone = 'project:resolution-submitted-postpone',
  resolutionDeclinedDesignerPostpone = 'project:resolution-declined-designer-postpone',
  resolutionDeclinedPostpone = 'project:resolution-declined-postpone',
  resolutionAcceptedChange = 'project:resolution-accepted-change',
  resolutionSubmittedCancel = 'project:resolution-submitted-cancel',
  resolutionDeclinedCancel = 'project:resolution-declined-cancel',
  resolutionSubmittedDesignerCancel = 'project:resolution-submitted-designer-cancel',
  resolutionDeclinedDesignerCancel = 'project:resolution-declined-designer-cancel',
  resolutionSubmittedDesignerModify = 'project:resolution-submitted-designer-modify',
  resolutionSubmittedModify = 'project:resolution-submitted-modify',
  resolutionDeclinedModify = 'project:resolution-declined-modify',
  resolutionDeclinedDesignerModify = 'project:resolution-declined-designer-modify',
  resolutionSubmittedDesignerAskToPay = 'project:resolution-submitted-designer-ask-to-pay',
  resolutionDeclinedDesignerAskToPay = 'project:resolution-declined-designer-ask-to-pay',
  resolutionDeclinedAskToPay = 'project:resolution-declined-ask-to-pay',
  resolutionSubmittedAskToPay = 'project:resolution-submitted-ask-to-pay',
  projectCompleted = 'project:completed',
  moneyTransfered = 'project:money-transfer-after-project-completed',
  offerExpired = 'project:offer-expired',
  offerModified = 'project:offer-modified',
  offerEditingDesigner = 'project:editing-offer-designer',
  offerEditingClient = 'project:editing-offer-updated',
  offerReceivedModified = 'project:offer-received-modified',
  offerCanceled = 'project:offer-canceled-designer',
  offerCanceledClient = 'project:offer-canceled',
  projectPhaseStarted = 'project:phase-started-designer',
  resolutionCanceledDesigner = 'project:resolution-canceled-designer',
  resolutionCanceled = 'project:resolution-canceled',
  designerDeclined = 'designer:declined',
  moneyTransferInitiate = 'project:money-transfer-initiate-for-completed-phases',
  moneyTransferedSuccessfully = 'project:money-transfer-success',
  invoiceFromDesigner = 'project:completed-designer',
  revisionStarted = 'phases:phase-revision-started',
  revisionStartedDesigner = 'phases:phase-revision-started-designer',
  revisionSubmitted = 'phases:phase-revision-submitted',
  phaseMoneyTransferInitiate = 'project:money-transfer-initiate-for-completed-phase',
  phaseMoneyTransferred = 'project:phase-money-transfer-success',
  projectPhaseTerminatedDesigner = 'project:phase-terminated-designer',
  projectPhaseTerminated = 'project:phase-terminated',
  projectTerminatedOfferUpdatedDesigner = 'project:terminated-offer-updated-designer',
  projectTerminatedOfferUpdated = 'project:terminated-offer-updated',
}

export const EventData: any = {
  [Events.created]: {
    component: CreatedEvent,
  },
  [Events.participate]: {
    component: Default,
  },
  [Events.offers]: {
    component: OfferEvent,
  },
  [Events.offerSubmitted]: {
    component: ParticipatedEvent,
  },
  [Events.offerReceived]: {
    component: OfferReceived,
  },
  [Events.offerSelectedDesigner]: {
    component: Default,
  },
  [Events.phaseStarted]: {
    component: Default,
  },
  [Events.phaseStartedDesigner]: {
    component: Default,
  },
  [Events.offerSelected]: {
    component: ProjectPhaseStart,
  },
  [Events.resolutionSubmitted]: {
    component: ResolutionSubmitted,
  },
  [Events.resolutionAccepted]: {
    component: ResolutionAccepted,
  },
  [Events.resolutionChangesSubmitted]: {
    component: ResolutionChangesSubmitted,
  },
  [Events.resolutionDeclined]: {
    component: ResolutionDeclined,
  },
  [Events.signCTA]: {
    component: SignCTA,
  },
  [Events.projectCancelled]: {
    component: Default,
  },
  [Events.projectCancelledDesigner]: {
    component: Default,
  },
  [Events.phaseSubmit]: {
    component: PhaseSubmit,
  },
  [Events.phaseReview]: {
    component: PhaseReview,
  },
  [Events.phaseCanceled]: {
    component: PhaseCanceled,
  },
  [Events.projectPhaseEnded]: {
    component: Default,
  },
  [Events.projectPhaseStart]: {
    component: ProjectPhaseStart,
  },
  [Events.resolutionSubmittedDesignerPostpone]: {
    component: ResolutionDefault,
  },
  [Events.resolutionSubmittedPostpone]: {
    component: ResolutionModify,
  },
  [Events.resolutionDeclinedDesignerPostpone]: {
    component: Default,
  },
  [Events.resolutionDeclinedPostpone]: {
    component: Default,
  },
  [Events.resolutionAcceptedChange]: {
    component: ResolutionDefault,
  },
  [Events.resolutionSubmittedCancel]: {
    component: ResolutionDefault,
  },
  [Events.resolutionDeclinedCancel]: {
    component: Default,
  },
  [Events.resolutionSubmittedDesignerCancel]: {
    component: ResolutionModify,
  },
  [Events.resolutionDeclinedDesignerCancel]: {
    component: Default,
  },
  [Events.resolutionSubmittedDesignerModify]: {
    component: ResolutionDefault,
  },
  [Events.resolutionSubmittedModify]: {
    component: ResolutionModify,
  },
  [Events.resolutionDeclinedModify]: {
    component: Default,
  },
  [Events.resolutionDeclinedDesignerModify]: {
    component: Default,
  },
  [Events.resolutionSubmittedDesignerAskToPay]: {
    component: ResolutionDefault,
  },
  [Events.resolutionDeclinedDesignerAskToPay]: {
    component: Default,
  },
  [Events.resolutionDeclinedAskToPay]: {
    component: Default,
  },
  [Events.resolutionSubmittedAskToPay]: {
    component: ResolutionModify,
  },
  [Events.projectCompleted]: {
    component: Default,
  },
  [Events.moneyTransfered]: {
    component: Default,
  },
  [Events.offerExpired]: {
    component: OfferExpired,
  },
  [Events.offerModified]: {
    component: ParticipatedEvent,
  },
  [Events.offerEditingClient]: {
    component: OfferEditing,
  },
  [Events.offerEditingDesigner]: {
    component: OfferEditing,
  },
  [Events.offerReceivedModified]: {
    component: OfferReceived,
  },
  [Events.offerCanceled]: {
    component: Default,
  },
  [Events.offerCanceledClient]: {
    component: Default,
  },
  [Events.projectPhaseStarted]: {
    component: Default,
  },
  [Events.resolutionCanceledDesigner]: {
    component: Default,
  },
  [Events.resolutionCanceled]: {
    component: Default,
  },
  [Events.designerDeclined]: {
    component: Default,
  },
  [Events.moneyTransferInitiate]: {
    component: Default,
  },
  [Events.moneyTransferedSuccessfully]: {
    component: Default,
  },
  [Events.invoiceFromDesigner]: {
    component: Default,
  },
  [Events.revisionStarted]: {
    component: Default,
  },
  [Events.revisionSubmitted]: {
    component: Default,
  },
  [Events.revisionStartedDesigner]: {
    component: PhaseSubmit,
  },
  [Events.phaseMoneyTransferInitiate]: {
    component: Default,
  },
  [Events.phaseMoneyTransferred]: {
    component: Default,
  },
  [Events.projectPhaseTerminatedDesigner]: {
    component: ParticipatedEvent,
  },
  [Events.projectPhaseTerminated]: {
    component: Default,
  },
  [Events.projectTerminatedOfferUpdatedDesigner]: {
    component: Default,
  },
  [Events.projectTerminatedOfferUpdated]: {
    component: ProjectPhaseStart,
  },
}
