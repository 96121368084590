import Chat from '@/shared/interfaces/models/project/chat'
import User from '@/shared/interfaces/models/user/user'
import _ from 'lodash'
import moment from 'moment'

export default class ChatMessage {
  time: any
  initials: any
  message: string
  incoming: boolean
  systemMessage: boolean
  profilePhoto: string

  constructor(data: Chat, currentUser: User | null) {
    this.time = ChatMessage.processDate(data)
    this.initials = ChatMessage.getInitials(data)
    this.profilePhoto = ChatMessage.getProfilePhoto(data)
    this.message = data.message
    this.incoming = ChatMessage.checkIfIncoming(data, currentUser)
    this.systemMessage = data.user === null
  }

  private static checkIfIncoming(data: Chat, currenUser: User | null): boolean {
    if (data.type === 'event' && data.role_show_to === 'both') {
      return true
    }

    return data.user === null || (currenUser !== null && data.user.uuid !== currenUser.uuid)
  }

  private static getInitials(data: Chat): string | null {
    if (data.user === null || data.type === 'event') {
      return 'GD'
    }

    if (data.user.media.length > 0) {
      return null
    }

    return data.user.first_name.substring(0, 1) + data.user.last_name.substring(0, 1)
  }

  private static getProfilePhoto(data: Chat): string {
    if (!data.user || data.type === 'event') {
      return ''
    }

    if (data.user.media.length > 0) {
      return _.find(data.user.media, { tag: 'user-details-profile-picture' }).url
    }

    return ''
  }

  private static processDate(data: Chat): any {
    const date = moment.unix(Number(data.created_at))

    if (date.isAfter(moment().subtract(1, 'days'))) {
      return date.format('HH:mm')
    }

    return date.format('DD/MM/YYYY')
  }
}
